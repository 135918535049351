import type { ImageSourceSetsRecord } from "@/types/of-image.types";
import { isNonEmptyString } from "@/utils/type-guards/isNonEmptyStringGuard";
import { getRecordEntries } from "@/utils/getRecordEntries";
import { ImageRatio } from "@/constants/image-ratio.constants";
import { MEDIA_QUERY } from "@/constants/media-query.constant";
import { tryToResult } from "@/utils/result";
import { error } from "@/utils/logger";
import { imageUrlService } from "@/services/image-url-service";

export const pxIcon = (
  px: number,
  lightModeUrl: string,
  darkModeUrl?: string,
): ImageSourceSetsRecord => {
  const darkModeUrlNormalized = isNonEmptyString(darkModeUrl)
    ? darkModeUrl
    : lightModeUrl;

  return getRecordEntries(DEFAULT_SQUARE_ICON_SOURCE_SETS_RECORD).reduce(
    (record, [key, value]) => {
      const urls = tryToResult(() => {
        return {
          lightModeUrl: imageUrlService
            .buildImgSrc({
              ...value,
              src: lightModeUrl,
              width: px,
            })
            .unwrap(),
          darkModeUrl: imageUrlService
            .buildImgSrc({
              ...value,
              src: darkModeUrlNormalized,
              width: px,
            })
            .unwrap(),
        };
      });

      if (urls.isErr()) {
        error(urls.error.message, urls.error);
        return record;
      }

      record[key] = {
        ...value,
        width: px,
        lightModeUrl: urls.value.lightModeUrl,
        darkModeUrl: urls.value.darkModeUrl,
      };

      return record;
    },
    { ...DEFAULT_SQUARE_ICON_SOURCE_SETS_RECORD },
  );
};

const DEFAULT_SQUARE_ICON_SOURCE_SETS_RECORD: ImageSourceSetsRecord = {
  mobile: {
    media: MEDIA_QUERY.mobile,
    width: 24,
    ratio: ImageRatio.Square,
    lightModeUrl: "",
    darkModeUrl: "",
  },
  tabletPortrait: {
    media: MEDIA_QUERY.tabletPortrait,
    width: 24,
    ratio: ImageRatio.Square,
    lightModeUrl: "",
    darkModeUrl: "",
  },
  tabletLandscape: {
    media: MEDIA_QUERY.tabletLandscape,
    width: 24,
    ratio: ImageRatio.Square,
    lightModeUrl: "",
    darkModeUrl: "",
  },
  desktop: {
    media: MEDIA_QUERY.desktop,
    width: 24,
    ratio: ImageRatio.Square,
    lightModeUrl: "",
    darkModeUrl: "",
  },
};
