import type { FC } from "react";
import type { MatchCard } from "@motain/xpa-proto-files-web/lib/types/match_card";
import { ImageWithSets } from "@/components/image-with-sets/ImageWithSets";
import { pxIcon } from "@/utils/helpers/pxIcon";
import styles from "./SimpleMatchCardTeam.module.scss";
import classNames from "classnames";

type SimpleMatchCardTeamProps = MatchCard["homeTeam" | "awayTeam"] & {
  className?: string;
};

export const SimpleMatchCardTeam: FC<SimpleMatchCardTeamProps> = (props) => {
  const { name, score, imageObject, className } = props;

  return (
    <div className={classNames(className, styles.simpleMatchCardTeam)}>
      <div className={styles.simpleMatchCardTeam__logo}>
        <ImageWithSets
          image={pxIcon(
            22,
            imageObject?.path ?? "/assets/images/entity-icon-placeholder.svg",
          )}
          alt={imageObject?.alt ?? ""}
        />
      </div>

      <span className={styles.simpleMatchCardTeam__name}>{name}</span>
      <span className={styles.simpleMatchCardTeam__score}>{score}</span>
    </div>
  );
};
