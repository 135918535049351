import type {
  ImageSourceSetsRecord,
  ImageSourceSetsRecordWithoutUrl,
} from "@/types/of-image.types";
import { isNonEmptyString } from "@/utils/type-guards/isNonEmptyStringGuard";
import { getRecordEntries } from "@/utils/getRecordEntries";
import { tryToResult } from "@/utils/result";
import { error } from "@/utils/logger";
import { imageUrlService } from "@/services/image-url-service";

export const getImageSourceSets = (
  sourceSets: ImageSourceSetsRecordWithoutUrl,
  lightModeUrl: string,
  darkModeUrl: string = lightModeUrl,
): ImageSourceSetsRecord => {
  const darkModeUrlNormalized = isNonEmptyString(darkModeUrl)
    ? darkModeUrl
    : lightModeUrl;

  return getRecordEntries(sourceSets).reduce(
    (record, [key, value]) => {
      const urls = tryToResult(() => {
        return {
          lightModeUrl: imageUrlService
            .buildImgSrc({ ...value, src: lightModeUrl })
            .unwrap(),
          darkModeUrl: imageUrlService
            .buildImgSrc({
              ...value,
              src: darkModeUrlNormalized,
            })
            .unwrap(),
        };
      });

      if (urls.isErr()) {
        error(urls.error.message, urls.error);
        return record;
      }

      record[key] = {
        ...value,
        ...urls.value,
      };

      return record;
    },
    {
      mobile: { ...sourceSets.mobile, lightModeUrl: "", darkModeUrl: "" },
      tabletPortrait: {
        ...sourceSets.mobile,
        lightModeUrl: "",
        darkModeUrl: "",
      },
      tabletLandscape: {
        ...sourceSets.mobile,
        lightModeUrl: "",
        darkModeUrl: "",
      },
      desktop: { ...sourceSets.mobile, lightModeUrl: "", darkModeUrl: "" },
    },
  );
};
