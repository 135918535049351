import { parseStringToDate } from "@/utils/helpers/parseStringToDate";
import { isTheSameDay } from "@/utils/helpers/isTheSameDay";

export const isToday = (date: string | Date): boolean => {
  if (typeof date === "string") {
    const normalizedDate = parseStringToDate(date);

    if (normalizedDate.isErr()) {
      return false;
    }

    return isTheSameDay(normalizedDate.value, new Date());
  }

  return isTheSameDay(date, new Date());
};
