import styles from "./WatchNow.module.scss";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import type { FC } from "react";
import useTranslation from "next-translate/useTranslation";

export const WatchNow: FC = () => {
  const { t } = useTranslation("web-payments");

  return (
    <div className={styles.container}>
      <ImageWithFallback
        fallbackImage={null}
        width={9}
        height={12}
        alt=""
        src={"https://images.onefootball.com/cw/icons/watch-light.svg"}
        darkModeSrc={"https://images.onefootball.com/cw/icons/watch-dark.svg"}
        className={styles.icon}
      />

      <span className={"title-8-bold"}>{t`MATCH_WATCH_CTA`}</span>
    </div>
  );
};
