/**
 * Parses given string timestamp into Date adjusting for timezone.
 * Useful when non-standard timestamps should be parsed into date
 * e.g. `new Date("2006-01-06")` in Brazil is into this Date:
 * `Sun Jan 01 2006 16:00:00 GMT-0800 (Pacific Standard Time)` so we have to
 * this function to adjust for the timezone.
 */
import type { Result } from "@/utils/result";
import { err, tryToResult } from "@/utils/result";

export const parseStringToDate = (
  timestampOrDate: string | Date,
): Result<Date> => {
  return tryToResult(() => {
    const utcDate = new Date(timestampOrDate.valueOf());

    if (isNaN(+utcDate)) {
      return err("Invalid Date");
    }

    return new Date(
      utcDate.getUTCFullYear(),
      utcDate.getUTCMonth(),
      utcDate.getUTCDate(),
      utcDate.getUTCHours(),
      utcDate.getUTCMinutes(),
      utcDate.getUTCSeconds(),
      utcDate.getUTCMilliseconds(),
    );
  });
};
