import type { FC } from "react";
import type { Immutable } from "@/types/immutable";
import type { MatchCard } from "@motain/xpa-proto-files-web/lib/types/match_card";
import {
  MatchPeriod,
  OttStreamType,
} from "@motain/xpa-proto-files-web/lib/types/match_card";
import { HIGHLIGHTS_IMAGE_SOURCE_SETS_RECORD } from "./highlightsImageSourceSetsRecord";
import { ACTIVE_GAME_MATCH_PERIODS } from "../activeGameMatchPeriods";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { SimpleMatchCardTeam } from "../simple-match-card-team/SimpleMatchCardTeam";
import { ImageWithSets } from "@/components/image-with-sets/ImageWithSets";
import useTranslation from "next-translate/useTranslation";
import styles from "./SimpleMatchCard.module.scss";
import { getImageSourceSets } from "@/utils/helpers/getImageSourceSets";
import classNames from "classnames";
import { isToday } from "@/utils/helpers/isToday";
import { WarningIcon } from "@/components/warning-icon/WarningIcon";
import { DurationLabel } from "@/components/duration-label/DurationLabel";
import { isNonNullable } from "@/types/isNonNullable";
import { isNullable } from "@/types/isNullable";
import type { Nullable } from "@/types/nullable";
import { WatchNow } from "../watch-now/WatchNow";

const IMAGE_SIZE = 20;

export const SimpleMatchCard: FC<Nullable<Immutable<MatchCard>>> = (
  matchCard,
) => {
  const { t } = useTranslation("web-payments");

  if (isNullable(matchCard)) {
    return null;
  }

  const isLiveMatchPeriod = ACTIVE_GAME_MATCH_PERIODS.has(matchCard.period);
  const isOttGame =
    matchCard.ottStreamType === OttStreamType.OTT_STREAM_TYPE_FREE_TO_AIR ||
    matchCard.ottStreamType === OttStreamType.OTT_STREAM_TYPE_PAY_PER_VIEW;

  const matchPeriodLive = () => {
    switch (matchCard.period) {
      case MatchPeriod.HALF_TIME:
        return (
          <span
            className={classNames(
              "title-8-medium",
              styles.simpleMatchCard__infoMessage,
              styles.simpleMatchCard__infoMessage__secondary,
            )}
          >
            {matchCard.timePeriod}
          </span>
        );
      case MatchPeriod.PENALTIES:
        return (
          <>
            <span
              className={classNames(
                "title-8-bold",
                styles.simpleMatchCard__live,
                styles.simpleMatchCard__live__penalties,
              )}
            >
              {matchCard.timePeriod}
            </span>
            <span
              className={classNames(
                "title-8-medium",
                styles.simpleMatchCard__infoMessage,
                styles.simpleMatchCard__infoMessage__secondary,
              )}
            >
              {t`MATCH_PENALTIES_SHORT_CARD`}
            </span>
          </>
        );
      default:
        return (
          <span
            className={classNames("title-8-bold", styles.simpleMatchCard__live)}
          >
            {matchCard.timePeriod}
          </span>
        );
    }
  };

  return (
    <article className={styles.simpleMatchCard}>
      <div
        className={classNames(
          isNonNullable(matchCard.highlights) &&
            styles.simpleMatchCard__contentWithHighlights,
          styles.simpleMatchCard__content,
        )}
      >
        <div className={styles.simpleMatchCard__teamsContent}>
          {matchCard.homeTeam && (
            <SimpleMatchCardTeam
              className={styles.simpleMatchCard__teamContent}
              name={matchCard.homeTeam.name}
              score={matchCard.homeTeam.score}
              aggregatedScore={matchCard.homeTeam.aggregatedScore}
              imageObject={matchCard.homeTeam.imageObject}
            />
          )}

          {matchCard.awayTeam && (
            <SimpleMatchCardTeam
              className={styles.simpleMatchCard__teamContent}
              name={matchCard.awayTeam.name}
              score={matchCard.awayTeam.score}
              aggregatedScore={matchCard.awayTeam.aggregatedScore}
              imageObject={matchCard.awayTeam.imageObject}
            />
          )}
        </div>

        <div className={styles.simpleMatchCard__matchContent}>
          {matchCard.highlights ? (
            <div className={styles.simpleMatchCard__highlights}>
              {matchCard.highlights.imageObject && (
                <div className={styles.simpleMatchCard__highlightsImageWrapper}>
                  <ImageWithSets
                    image={getImageSourceSets(
                      HIGHLIGHTS_IMAGE_SOURCE_SETS_RECORD,
                      matchCard.highlights.imageObject.path,
                    )}
                    alt={matchCard.highlights.imageObject.alt}
                    isPreloaded={matchCard.highlights.imageObject.isPreloaded}
                    classNames={styles.simpleMatchCard__highlightsImage}
                  />
                </div>
              )}
              <DurationLabel
                duration={matchCard.highlights.duration}
                className={styles.simpleMatchCard__highlightsDuration}
              />
            </div>
          ) : (
            <>
              {matchCard.homeTeam?.aggregatedScore !== "" &&
                matchCard.awayTeam?.aggregatedScore !== "" && (
                  <span
                    className={classNames(
                      "title-9-regular",
                      styles.simpleMatchCard__aggregatedScore,
                    )}
                  >
                    {matchCard.period === MatchPeriod.PRE_MATCH
                      ? t`MATCH_FIRST_LEG`
                      : t`MATCH_AGGREGATE`}
                    &#32;{matchCard.homeTeam?.aggregatedScore}&#32;-&#32;
                    {matchCard.awayTeam?.aggregatedScore}
                  </span>
                )}

              {isLiveMatchPeriod ? (
                <>
                  {matchPeriodLive()}
                  {isOttGame && <WatchNow />}
                </>
              ) : (
                <>
                  {matchCard.period === MatchPeriod.PRE_MATCH && (
                    <span className={styles.simpleMatchCard__preMatch}>
                      {!isToday(matchCard.kickoff) && (
                        <time
                          className={"title-8-bold"}
                          dateTime={matchCard.kickoff}
                        >
                          {matchCard.kickoffFormatted}
                        </time>
                      )}
                      <time
                        dateTime={matchCard.kickoff}
                        className={classNames(
                          styles.simpleMatchCard__infoMessage,
                          isToday(matchCard.kickoff)
                            ? "title-8-bold"
                            : "title-8-medium",
                        )}
                      >
                        {matchCard.kickoffTimeFormatted}
                      </time>
                      {isOttGame && <WatchNow />}
                    </span>
                  )}
                  {matchCard.period === MatchPeriod.FULL_TIME && (
                    <>
                      <time
                        dateTime={matchCard.kickoff}
                        className={"title-8-bold"}
                      >
                        {matchCard.kickoffFormatted}
                      </time>
                      <span
                        className={classNames(
                          "title-8-medium",
                          styles.simpleMatchCard__infoMessage,
                          styles.simpleMatchCard__infoMessage__secondary,
                        )}
                      >
                        {matchCard.timePeriod}
                      </span>
                    </>
                  )}
                  {matchCard.period === MatchPeriod.FULL_TIME_PENALTIES && (
                    <>
                      <span
                        className={classNames(
                          "title-8-bold",
                          styles.simpleMatchCard__infoMessage__secondary,
                        )}
                      >
                        {matchCard.timePeriod}
                      </span>
                      <span
                        className={classNames(
                          "title-8-medium",
                          styles.simpleMatchCard__infoMessage,
                          styles.simpleMatchCard__infoMessage__secondary,
                        )}
                      >{t`MATCH_PENALTIES_SHORT_CARD`}</span>
                    </>
                  )}
                  {matchCard.period === MatchPeriod.ABANDONED && (
                    <>
                      <WarningIcon />
                      <span
                        className={classNames(
                          "title-8-medium",
                          styles.simpleMatchCard__warningMessage,
                        )}
                      >
                        {matchCard.timePeriod}
                      </span>
                    </>
                  )}
                  {matchCard.period === MatchPeriod.INTERRUPT && (
                    <>
                      <span
                        className={classNames(
                          "title-8-bold",
                          styles.simpleMatchCard__warningMessage,
                        )}
                      >
                        {matchCard.timePeriod}
                      </span>
                    </>
                  )}
                  {matchCard.period === MatchPeriod.POSTPONED && (
                    <>
                      <WarningIcon />
                      <span
                        className={classNames(
                          "title-8-medium",
                          styles.simpleMatchCard__warningMessage,
                        )}
                      >
                        {matchCard.timePeriod}
                      </span>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {isLiveMatchPeriod && (
        <span className={styles.simpleMatchCard__liveIndicator}></span>
      )}

      {matchCard.competitionName.length > 0 && (
        <footer className={styles.simpleMatchCard__footer}>
          <div className={styles.simpleMatchCard__competition}>
            {matchCard.competitionLogo !== undefined && (
              <div className={styles.simpleMatchCard__competitionLogo}>
                <ImageWithFallback
                  width={IMAGE_SIZE}
                  height={IMAGE_SIZE}
                  alt={matchCard.competitionLogo.alt}
                  src={matchCard.competitionLogo.path}
                  darkModeSrc={
                    matchCard.competitionLogo.darkModePath !== ""
                      ? matchCard.competitionLogo.darkModePath
                      : matchCard.competitionLogo.path
                  }
                />
              </div>
            )}

            <p
              className={classNames(
                "title-9-regular",
                styles.simpleMatchCard__competitionName,
              )}
            >
              {matchCard.competitionName}
            </p>
          </div>
          <p className={"title-9-regular"}>{matchCard.ottBadge}</p>
        </footer>
      )}
    </article>
  );
};
