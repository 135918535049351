import React from "react";
import styles from "./DurationLabel.module.scss";
import classnames from "classnames";
import useTranslation from "next-translate/useTranslation";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";

export const DurationLabel: React.FC<{
  duration: string;
  className: string | undefined;
}> = ({ duration, className }) => {
  const { t } = useTranslation("web-payments");

  return (
    <div className={classnames(styles.durationLabel, className)}>
      <div className={styles.durationLabel__icon}>
        <ImageWithFallback
          width={14}
          height={14}
          alt={t`PLAY_ICON_ALT_TEXT`}
          src="https://images.onefootball.com/cw/icons/simple-play-dark.svg"
        />
      </div>
      <span className={styles.durationLabel__text}>{duration}</span>
    </div>
  );
};
