import styles from "./WarningIcon.module.scss";
import type { FC } from "react";
import classNames from "classnames";
import { isNullable } from "@/types/isNullable";

export const WarningIcon: FC<{ testId?: string; className?: string }> = (
  props,
) => {
  return (
    <svg
      className={classNames(
        props.className,
        styles.ofWarningIcon,
        isNullable(props.className) && styles.defaultColor,
      )}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={props.testId}
    >
      <path
        clipRule="evenodd"
        d="M12.248 6.25a.75.75 0 01.75.75v8.12a.75.75 0 11-1.5 0V7a.75.75 0 01.75-.75z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path d="M12.248 18.87a1 1 0 100-2 1 1 0 000 2z" fill="currentColor" />
      <path
        clipRule="evenodd"
        d="M15.657 4.213l7.04 12.12c1.53 2.64-.37 5.937-3.419 5.937H5.208c-3.047 0-4.95-3.306-3.418-5.937l7.04-12.12c1.519-2.617 5.308-2.617 6.827 0zm-1.297.753c-.941-1.622-3.292-1.622-4.233 0l-7.04 12.12c-.949 1.63.23 3.684 2.121 3.684h14.07c1.892 0 3.072-2.044 2.121-3.684l-7.04-12.12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};
