import { MatchPeriod } from "@motain/xpa-proto-files-web/lib/types/match_card";
import type { Immutable } from "@/types/immutable";

export const ACTIVE_GAME_MATCH_PERIODS: Immutable<Set<MatchPeriod>> = new Set([
  MatchPeriod.FIRST_HALF,
  MatchPeriod.SECOND_HALF,
  MatchPeriod.EXTRA_FIRST_HALF,
  MatchPeriod.EXTRA_SECOND_HALF,
  MatchPeriod.HALF_TIME,
  MatchPeriod.PENALTIES,
]);
