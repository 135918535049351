import type { ImageSourceSetsRecordWithoutUrl } from "@/types/of-image.types";
import { MEDIA_QUERY } from "@/constants/media-query.constant";
import { ImageRatio } from "@/constants/image-ratio.constants";

export const HIGHLIGHTS_IMAGE_SOURCE_SETS_RECORD: ImageSourceSetsRecordWithoutUrl =
  {
    mobile: {
      media: MEDIA_QUERY.mobile,
      width: 81,
      ratio: ImageRatio.Wide16to9,
    },
    tabletPortrait: {
      media: MEDIA_QUERY.tabletPortrait,
      width: 100,
      ratio: ImageRatio.Wide16to9,
    },
    tabletLandscape: {
      media: MEDIA_QUERY.tabletLandscape,
      width: 100,
      ratio: ImageRatio.Wide16to9,
    },
    desktop: {
      media: MEDIA_QUERY.desktop,
      width: 105,
      ratio: ImageRatio.Wide16to9,
    },
  };
