import type { ReactElement } from "react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { error } from "@/utils/logger";
import useTranslation from "next-translate/useTranslation";
import { Button } from "@/components/button/Button";
import { useScreenSize } from "@/utils/hooks/useScreenSize";
import { isNonNullable } from "@/types/isNonNullable";
import { isInViewport } from "@/utils/isInViewport";
import styles from "./HorizontalScrollingContainer.module.scss";
import classNames from "classnames";
import { isNullable } from "@/types/isNullable";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";

interface HorizontalScrollingContainerItem {
  element: ReactElement;
  key: string;
  liClassName?: string;
  isDark?: boolean;
}

interface HorizontalScrollingContainerProps {
  desktopChunkItemsCount?: number;
  mobileChunkItemsCount?: number;
  tabletPortraitChunkItemsCount?: number;
  tabletLandscapeChunkItemsCount?: number;
  autoScrollTimeoutMs?: number;
  items: Array<HorizontalScrollingContainerItem>;
  isDark?: boolean;
}

export const HorizontalScrollingContainer: React.FC<
  HorizontalScrollingContainerProps
> = ({
  desktopChunkItemsCount = 3,
  mobileChunkItemsCount = 1,
  tabletPortraitChunkItemsCount = 2,
  tabletLandscapeChunkItemsCount = 3,
  autoScrollTimeoutMs,
  items,
  isDark = false, // required to enforce dark mode in the carousel
}): ReactElement => {
  const screenSize = useScreenSize();
  const { t } = useTranslation("web-payments");
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>();
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>();
  const [touchedIndex, setTouchedIndex] = useState<number | undefined>();
  const listRef = useRef<HTMLUListElement>(null);
  const [offset, setOffset] = useState(0);

  const listItemsCount = items.length;
  const lastCarouselChunkItemsRemainder =
    listItemsCount % desktopChunkItemsCount;
  const lastCarouselChunkMissingItemsCount =
    lastCarouselChunkItemsRemainder === 0
      ? 0
      : desktopChunkItemsCount - lastCarouselChunkItemsRemainder;
  const totalItemsCount = listItemsCount + lastCarouselChunkMissingItemsCount;
  const chunksCount = Math.ceil(listItemsCount / desktopChunkItemsCount);
  const lastCarouselChunkMissingItems = Array(
    lastCarouselChunkMissingItemsCount,
  ).fill("");

  useEffect(() => {
    const isSupportedScreenSizeForAutoScroll = screenSize === "mobile";
    const isAutoScrollAllowed =
      isNonNullable(autoScrollTimeoutMs) && isSupportedScreenSizeForAutoScroll;
    const hasUserInteractions =
      isNonNullable(focusedIndex) ||
      isNonNullable(hoveredIndex) ||
      isNonNullable(touchedIndex);

    if (!isAutoScrollAllowed || hasUserInteractions) {
      return;
    }

    const interval = setInterval(() => {
      if (isNullable(listRef.current)) {
        return;
      }

      const mapListItemHtmlElements = Array.prototype.map.bind(
        listRef.current.children,
      );
      const listItemsVisibilityListPromise = mapListItemHtmlElements(
        (child: HTMLElement) => isInViewport(child, { threshold: 0.7 }),
      );

      Promise.all(listItemsVisibilityListPromise)
        .then((listItemsVisibilityList) => {
          if (isNullable(listRef.current)) {
            return;
          }

          let nextVisibleListItem;
          const lastVisibleListItemIndex =
            listItemsVisibilityList.lastIndexOf(true);

          if (lastVisibleListItemIndex === items.length - 1) {
            nextVisibleListItem = 0;
          } else {
            nextVisibleListItem = lastVisibleListItemIndex + 1;
          }

          listRef.current.children[nextVisibleListItem]?.scrollIntoView({
            behavior: "smooth",
            inline: "center",
            block: "nearest",
          });
        })
        .catch((e) => {
          error(e);
        });

      return;
    }, autoScrollTimeoutMs);

    return () => {
      clearInterval(interval);
    };
  }, [
    autoScrollTimeoutMs,
    items,
    focusedIndex,
    hoveredIndex,
    touchedIndex,
    screenSize,
  ]);

  useEffect(() => {
    setOffset(0);

    if (isNullable(listRef.current)) {
      return;
    }

    // Scroll back to the first element in mobile screen when the number of items changes
    // This is needed since the offset is always "0" in mobile/tablet
    listRef.current.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [items]);

  const moveForward = useCallback(() => {
    setOffset(offset + 1);
  }, [offset, setOffset]);
  const moveBack = useCallback(() => {
    setOffset(offset - 1);
  }, [offset, setOffset]);
  const listItems = useMemo(
    () =>
      items.map((child, index) => (
        <li
          className={classNames(styles.listItem, child.liClassName)}
          key={child.key}
          onMouseEnter={() => {
            setFocusedIndex(index);
          }}
          onMouseLeave={() => {
            setFocusedIndex(undefined);
          }}
          onFocus={() => {
            setHoveredIndex(index);
          }}
          onBlur={() => {
            setHoveredIndex(undefined);
          }}
          onTouchStart={() => {
            setTouchedIndex(index);
          }}
          onTouchEnd={() => {
            setTouchedIndex(undefined);
          }}
        >
          {child.element}
        </li>
      )),
    [items],
  );
  const lastCarouselMissingListItems = useMemo(
    () =>
      lastCarouselChunkMissingItems.map((_, index) => (
        <li className={styles.lastItem} key={index} />
      )),
    [lastCarouselChunkMissingItems],
  );

  return (
    <div
      className={styles.container}
      style={{
        "--offset": offset,
        "--chunks-count": chunksCount,
        "--total-items-count": totalItemsCount,
        "--hor-scr-container-visible-items-count-mobile": mobileChunkItemsCount,
        "--hor-scr-container-visible-items-count-tablet-portrait":
          tabletPortraitChunkItemsCount,
        "--hor-scr-container-visible-items-count-tablet-landscape":
          tabletLandscapeChunkItemsCount,
      }}
    >
      {offset > 0 && (
        <Button
          variant="icon-only"
          onClick={moveBack}
          className={styles.arrowButtonLeft}
        >
          <span className="screen-reader-only">{t`CAROUSEL_MOVE_BACK`}</span>
          <ImageWithFallback
            width={42}
            height={42}
            alt=""
            src={
              !isDark
                ? "https://images.onefootball.com/cw/icons/simple-arrow-left-light-v2.svg"
                : "https://images.onefootball.com/cw/icons/simple-arrow-left-dark-v2.svg"
            }
            darkModeSrc={
              "https://images.onefootball.com/cw/icons/simple-arrow-left-dark-v2.svg"
            }
          ></ImageWithFallback>
        </Button>
      )}
      <div className={styles.visibleChunk}>
        <ul className={styles.list} ref={listRef}>
          {listItems}
          {lastCarouselMissingListItems}
        </ul>
      </div>
      {offset !== chunksCount - 1 && (
        <Button
          variant="icon-only"
          onClick={moveForward}
          className={styles.arrowButtonRight}
        >
          <span className="screen-reader-only">{t`CAROUSEL_MOVE_FORWARD`}</span>
          <ImageWithFallback
            width={42}
            height={42}
            alt=""
            src={
              !isDark
                ? "https://images.onefootball.com/cw/icons/simple-arrow-right-light.svg"
                : "https://images.onefootball.com/cw/icons/simple-arrow-right-dark.svg"
            }
            darkModeSrc={
              "https://images.onefootball.com/cw/icons/simple-arrow-right-dark.svg"
            }
          ></ImageWithFallback>
        </Button>
      )}
    </div>
  );
};
