export async function isInViewport(
  element: HTMLElement,
  options?: IntersectionObserverInit,
): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    const observer = new IntersectionObserver((entries) => {
      const isIntersecting = entries.every((entry) => entry.isIntersecting);
      resolve(isIntersecting);
      observer.disconnect();
    }, options);

    observer.observe(element);
  });
}
